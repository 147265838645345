import React, { useContext } from 'react'
import Title from './Title'
import { Link } from 'react-router-dom'
import QuantityBtn from './QuantityBtn'
import { CartContext } from './CartContext'

export default function Checkout() {
    let {cartItems} = useContext(CartContext)


    let cartEmpty = cartItems.length<=0 ? true : false
    let grandTotal = cartItems.reduce((total, product)=>{
        return total += product.price*product.quantity
    },0)
    const freeShippingPrice = 99

  return (
    <div>
        <Title mainTitle = "Your shopping car"/>
        {
            cartEmpty &&
            <div>
                Shopping car no product now<br/>
                <Link to="/">Go back to Home page</Link>
            </div>

        }


        {
            !cartEmpty &&
        <div>
            <div id="cartSection">
                {
                    cartItems.map(product=>(
                        <div key={product.id}>
                            <img src={process.env.PUBLIC_URL + '/img/' + product.image} alt={product.name}/>
                            {product.name}
                            {product.description}
                            {product.price}
                            Quantity: {product.quantity}
                            <QuantityBtn productInfo={product}/>
                        </div>
                    ))
                }
            </div>
            <div id="checkOutSection">
                <div>Total Amount:</div>
                <div>${grandTotal}</div>

                {
                    grandTotal >= freeShippingPrice ?
                    <div>Free Shipping!</div> :
                    <div>${freeShippingPrice}Free Shipping<br/>
                        -${freeShippingPrice-grandTotal}
                    </div>
                }
            </div>
        </div>
        }



    </div>
  )
}
