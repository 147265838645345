import styles from './ProductList.module.css'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Title from './Title'
import QuantityBtn from './QuantityBtn'

export default function ProductList() {

    // let productList = [
    //     {"id":1, "name": "Apple", "price":5,"image":"apple.jpg","desc":"Very good!"},
    //     {"id":2, "name": "Orange", "price":3,"image":"orange.jpg","desc":"Very good good!"},
    //     {"id":3, "name": "Mango", "price":4,"image":"mango.jpg","desc":"Very good man!"}
    // ]

    let [productList, setProductList] = useState([])
  
        useEffect(()=>{
            fetch('https://hoyinleung.github.io/demoapi/react-basic-product.json')
            .then(response => response.json())
            .then(data => setProductList(data))
            //console.log(productList)
        },[])

    // const [showProduct, setShowProduct] = useState(false)

    // const handleClick = ()=>{
    //     setProduct('HelloA______A')
    //     console.log(product)
    // }

    return (
        <>
            {/* {showProduct && <button onClick={()=>{setShowProduct(false)}}>Hidden Product</button>}
            {!showProduct &&<button onClick={()=>{setShowProduct(true)}}>Display Product</button>} */}
            {/* <h1 style={{}}>Please select the product</h1> */}
            <Title mainTitle="Please select the product"/>
            <div>
                {
                    //false && true = false, not display
                    //false && productList.map(product=>(
                    //showProduct && productList.map(product=>(
                    productList.map(product=>(
                        <div className={styles.productBorder} key={product.id}>
                            {product.name}<br/>
                            {product.price}<br/>
                            <Link to={'/product/'+product.id}>
                            <img src={process.env.PUBLIC_URL +'/img/'+ product.image} alt={product.name} /><br/>
                            </Link>
                            {product.description}<br/>
                            <QuantityBtn productInfo={product} />
                        </div>
                    ))
                }
            </div>
        </>
    )
}
