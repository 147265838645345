import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import Title from './Title'
import QuantityBtn from './QuantityBtn'

export default function ProductDetail() {
    let parms = useParams()
    let [productDetail, setProductDetail] = useState(null)

    useEffect(()=>{
      fetch('https://hoyinleung.github.io/demoapi/react-basic-product.json')
      .then(response => response.json())
      .then(data => {
        let productInfo = data.find((element)=>{
          return element.id === parseInt(parms.id)
        })
        setProductDetail(productInfo)
      })

  },[parms.id])

  return (
    <div>
        {
          productDetail &&
          <div>
            <Title mainTitle={productDetail.name + "Product information"}  />
            <img src={process.env.PUBLIC_URL+'/img/'+productDetail.image} alt={productDetail.name} width="400"/>
            <p>Name: {productDetail.name}</p>
            <p>Price: {productDetail.price}</p>
            <p>Desc: {productDetail.description}</p>
            <QuantityBtn productInfo={productDetail}/>
          </div>
        }
        <Link to="/">Back to ProductList</Link>

    </div>
  )
}
