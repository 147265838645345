import React from 'react'

export default function Title(props) {
  return (
    <div>
      <h1 style={{textAlign:"center", borderBottom : '5px solid orange'}}>Please select the product</h1>
      {props.mainTitle}
    </div>
  )
}
